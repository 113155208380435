import React from "react";
import { Box, Typography, CircularProgress, Alert, FormControlLabel, Switch } from "@mui/material";
import * as FaIcons from "react-icons/fa";
import LoadingButton from "@mui/lab/LoadingButton";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import { StyledLink } from "../../constants/styledComponents";

const ModalContent = ({
  backendStatus,
  translate,
  handleUpdate,
  isLocalHost,
  savedCompatibility,
  handleSwitchChange
}) => {
  if (backendStatus.state.isUpdating) {
      return (
          <>
              <Box sx={{ display: "flex", width: "100%", justifyContent: "center", mb: 2 }}>
                  <Typography id="modal-modal-title" variant="h6" component="h1">
                      {translate("update.updateInProgress")}
                  </Typography>
                  <span style={{ marginLeft: "20px" }}>
                      <CircularProgress size={23} />
                  </span>
              </Box>
              <Alert severity="info">
                  <Typography color="textPrimary">
                      <span dangerouslySetInnerHTML={{ __html: translate("update.halfHourMessage") }} />
                  </Typography>
                  <Typography color="textPrimary">
                      <span dangerouslySetInnerHTML={{ __html: translate("update.dontTurnOff") }} />
                  </Typography>
              </Alert>
          </>
      );
  }

  if (backendStatus.state.isUpdateRequired) {
      return (
          <>
              <Typography align={"center"} variant="h6" component="h1">
                  {translate("update.updateIsRequired")}
              </Typography>
              <Typography align={"center"} variant="h6" component="h1" mb={2}>
                  {translate("update.installNow")}
              </Typography>
              <LoadingButton
                  disabled={!backendStatus.state.isUpdateRequired}
                  className="float-sm-end m-1"
                  variant="outlined"
                  startIcon={<FaIcons.FaDownload />}
                  onClick={handleUpdate}
                  loading={backendStatus.state.isUpdateRequested}
              >
                  {translate("update.updateNow")}
              </LoadingButton>
          </>
      );
  }

  if (!backendStatus.state.isBackendCompatibleWithFrontend) {
      return (
          <>
              <Typography align={"center"} id="modal-modal-title" variant="h6" component="h1" mb={2}>
                  {translate("update.backendIncompatibilityDetected")}
              </Typography>
              <Alert severity="warning">
                  <Typography color="textPrimary">
                      {translate("update.notCompatibleMessage")}
                  </Typography>
              </Alert>
              <Box sx={{ display: "flex", mt: "15px", justifyContent: "space-around", width: "100%" }}>
                  <Box>
                      <EmailIcon color="primary" sx={{ mr: "5px" }} />
                      <StyledLink href="mailto:support@reyedar.com" target="_blank" color="textSecondary">
                          support@reyedar.com
                      </StyledLink>
                  </Box>
                  <Box>
                      <PhoneIcon color="primary" sx={{ mr: "5px" }} />
                      <StyledLink href="tel:+310887373000" underline="none" color="textSecondary">
                          +31 (0) 88 7373 000
                      </StyledLink>
                  </Box>
              </Box>
              {isLocalHost && (
                  <div style={{ marginTop: "10px" }}>
                      <Alert severity="info">
                          You are currently on a local environment. To bypass compatibility issues, toggle the
                          switch below:
                      </Alert>

                      <FormControlLabel
                          control={
                              <Switch
                                  onChange={handleSwitchChange}
                                  defaultChecked={JSON.parse(savedCompatibility)}
                              />
                          }
                          color="primary"
                          label="Bypass Compatibility"
                      />
                  </div>
              )}
          </>
      );
  }

    return (
        <>
            <Typography align={"center"} id="modal-modal-title" variant="h6" component="h1" mb={2}>
                {translate("update.undefinedBackend")}
            </Typography>
            <LoadingButton
                disabled={true}
                className="float-sm-end m-1"
                variant="outlined"
                startIcon={<FaIcons.FaDownload />}
                onClick={handleUpdate}
                loading={backendStatus.state.isUpdateRequested}
            >
                {translate("update.updateNow")}
            </LoadingButton>
        </>
    );
};

export default ModalContent;
