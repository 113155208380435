import { compatibleVersions } from "./compatibilityService";

export const CompatibilityMatrixService = {
  fetchMatrix: async (userPool) => {
    try {
      const result = await compatibleVersions(userPool);

        const matrix = result?.data || result;

      return matrix;
    } catch (error) {
      console.error("Error fetching compatibility matrix:", error.message);
      throw error;
    }
  },
};