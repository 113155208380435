import { useState, useEffect, useCallback } from "react";
import axios from "axios";

const useCheckRunningTests = (pollingInterval = 2000) => {
    const [isTestNotFailedAndNotReported, setIsTestNotFailedAndNotReported] = useState(false);

    const checkRunningTests = useCallback(async () => {
        try {
            const csrfToken = localStorage.getItem("csrfToken");
            const serverUri = import.meta.env.VITE_APP_SERVER_URI;
            const apiVersion = import.meta.env.VITE_APP_TEST_SERVICE_API_VERSION;

            const response = await axios.get(`${serverUri}/${apiVersion}/tests/running`, {
                responseType: "json",
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    "X-CSRF-Token": csrfToken,
                },
            });

            const { noFailedTestNotReported } = response.data;

            setIsTestNotFailedAndNotReported((prev) =>
                prev !== noFailedTestNotReported ? noFailedTestNotReported : prev
            );
        } catch (error) {
            console.error("Error checking running tests:", error);
        }
    }, []);

    useEffect(() => {
        checkRunningTests();

        const interval = setInterval(() => {
            checkRunningTests();
        }, pollingInterval);

        return () => clearInterval(interval);
    }, [checkRunningTests, pollingInterval]);

    return { isTestNotFailedAndNotReported, checkRunningTests };
};

export default useCheckRunningTests;
