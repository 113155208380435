import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DialogBase from "./DialogBase";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { useTheme } from "@mui/material";
import { useTranslation } from "../../context/TranslationContext";

const ConfirmationDialog = ({
    title,
    subtitle,
    isOpen,
    onClose,
    onConfirm,
    inProgress = false,
    confirmLabel,
    confirmDisabled = false,
    confirmIcon = <CheckCircleIcon />,
    icon
}) => {
    const theme = useTheme();
    const { translate } = useTranslation();

    const defaultConfirmLabel = confirmLabel || translate("confirm");
    return (
        <DialogBase
            title={title}
            subtitle={subtitle}
            isOpen={isOpen}
            onClose={onClose}
            onConfirm={!confirmDisabled ? onConfirm : undefined}
            inProgress={inProgress}
            icon={icon || <QuestionMarkIcon style={{ fontSize: "74px", color: "white" }} />} 
            bgColor={theme.palette.primary.main}
            confirmLabel={defaultConfirmLabel}
            confirmButtonColor="primary"
            confirmDisabled={confirmDisabled}
            confirmIcon={confirmIcon}
        />
    );
};

export default ConfirmationDialog;
