// src/components/UpdateWarningModal/styles.js
import { styled } from "@mui/material/styles";
import { Box, Dialog, DialogActions, Typography } from "@mui/material";

export const StyledDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    overflow: "visible",
    borderRadius: "14px",
    maxWidth: "540px",
    width: "100%",
  },
}));

export const IconWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  marginTop: "-35px",
}));

export const StyledIconCircle = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.warning.main,
  color: theme.palette.background.common,
  borderRadius: "50%",
  width: "100px",
  height: "100px",
  border: "10px solid",
  borderColor: theme.palette.background.common,
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),
  textAlign: "center",
}));

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  justifyContent: "center",
  marginBottom: theme.spacing(1),
}));
