import { createContext, useContext, useEffect, useRef, useState } from "react";
import { CompatibilityMatrixService } from "../services/compatibilityService/compatibilityMatrixService";
import useCognito from "../hooks/useCognito";
import AuthContext from "./authContext";

const CompatibilityMatrixContext = createContext();

const MATRIX_STORAGE_KEY = "compatibilityMatrix";
const FETCH_INTERVAL = 30 * 60 * 1000; // 30 minutes

export const CompatibilityMatrixProvider = ({ children }) => {
  const [matrix, setMatrix] = useState(null);
  const [lastFetched, setLastFetched] = useState(null);
  const fetchedOnceRef = useRef(false);
  const { userPool } = useCognito();
  const { isAuthenticated } = useContext(AuthContext);

  // Load from localStorage if available and fresh
  useEffect(() => {
    const stored = localStorage.getItem(MATRIX_STORAGE_KEY);
    const now = Date.now();

    if (stored) {
      try {
        const parsed = JSON.parse(stored);
        const isFresh = now - parsed.timestamp < FETCH_INTERVAL;

        if (isFresh && parsed.data) {
          setMatrix(parsed.data);
          setLastFetched(parsed.timestamp);
          return;
        }
      } catch (err) {
        console.warn("Failed to parse compatibility matrix from localStorage:", err);
      }
    }
  }, []);

  // Fetch matrix once after login
  useEffect(() => {
    const fetchMatrix = async () => {
      if (!isAuthenticated || !userPool) return;

      try {
        const matrixData = await CompatibilityMatrixService.fetchMatrix(userPool);
        const now = Date.now();

        setMatrix(matrixData);
        setLastFetched(now);

        localStorage.setItem(
          MATRIX_STORAGE_KEY,
          JSON.stringify({ data: matrixData, timestamp: now })
        );

        console.log("Compatibility matrix fetched and saved to localStorage.");
      } catch (err) {
        console.error("Failed to fetch compatibility matrix:", err);
      }
    };

    if (isAuthenticated && !fetchedOnceRef.current) {
      fetchedOnceRef.current = true;
      fetchMatrix();
    }

    const interval = setInterval(() => {
      if (isAuthenticated && userPool) {
        fetchMatrix();
      }
    }, FETCH_INTERVAL);

    return () => clearInterval(interval);
  }, [isAuthenticated, userPool]);

  return (
    <CompatibilityMatrixContext.Provider value={{ matrix, lastFetched }}>
      {children}
    </CompatibilityMatrixContext.Provider>
  );
};

export const useCompatibilityMatrix = () => useContext(CompatibilityMatrixContext);