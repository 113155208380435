import React from "react";
import { useTheme } from "@mui/material/styles";
import * as FaIcons from "react-icons/fa";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { StyledIconCircle } from "../UpdateWarningModal/styles";

const ModalIcon = ({ isUpdating, isUpdateRequired, isBackendCompatible }) => {
  const theme = useTheme();

  const getIconAndColor = () => {
    if (isUpdating) {
      return {
        icon: <FaIcons.FaDownload size={50} color="inherit" />,
        bgColor: theme.palette.info.main,
      };
    } else if (isUpdateRequired) {
      return {
        icon: <FaIcons.FaDownload size={50} />,
        bgColor: theme.palette.primary.main,
      };
    }
    else if (!isBackendCompatible) {
      return {
        icon: <PriorityHighIcon sx={{ fontSize: "65px" }} />,
        bgColor: theme.palette.warning.main,
      };
    } else {
      return {
        icon: <QuestionMarkIcon sx={{ fontSize: "65px" }} />,
        bgColor: theme.palette.warning.main,
      };
    }
  };

  const { icon, bgColor } = getIconAndColor();
  return (
    <StyledIconCircle style={{ backgroundColor: bgColor }}>
      {icon}
    </StyledIconCircle>
  );
};

export default ModalIcon;
